import "./StylingFiles/Style.css";
import team1 from "./img/team/team-1.jpg";
import team2 from "./img/team/team-2.jpg";
import team3 from "./img/team/team-3.jpg";
import team4 from "./img/team/team-4.jpg";

const MyTeam = () => {
  return (
    <>
       <section id="team" class="team">

<div class="container"  >

  <header class="section-header">
    <h2>Team</h2>
    <p>Our hard working team</p>
  </header>

  <div class="row gy-4">

    <div class="col-lg-3 col-md-6 d-flex align-items-stretch"   data-aos-delay="100">
      <div class="member">
        <div class="member-img">
          <img src={team1} class="img-fluid" alt="No_Image"/>
          <div class="social">
            <a href="https://www.facebook.com/home.php" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/techfusion752/" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="" target="_blank"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
        <div class="member-info">
          <h4>Muhammad Rizwan</h4>
          <span>UI / UX Designer</span>
          <p>I'm a talented UI & UX designer with 3 years of experience, also skilled in front-end development. With 10 successful projects under my belt, I create designs that marry beauty with functionality for delightful user experiences.</p>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 d-flex align-items-stretch"   data-aos-delay="200">
      <div class="member">
        <div class="member-img">
          <img src={team2} class="img-fluid" alt="No_Image"/>
          <div class="social">
            <a href="https://www.facebook.com/home.php" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/techfusion752/" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="" target="_blank"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
        <div class="member-info">
          <h4>Muhammad Khuram</h4>
          <span>React + Angular Developer</span>
          <p>I'm Experienced React and Angular developer. I specialize in crafting elegant user interfaces. With 3 successful projects under my belt, I'm dedicated to pushing front-end boundaries with passion and creativity</p>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 d-flex align-items-stretch"   data-aos-delay="300">
      <div class="member">
        <div class="member-img">
          <img src={team3} class="img-fluid" alt="No_Image"/>
          <div class="social">
            <a href="https://www.facebook.com/home.php" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/techfusion752/" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="https://www.linkedin.com/in/syed-muzzamal-234a47235/" target="_blank"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
        <div class="member-info">
          <h4>Syed Muzzamal</h4>
          <span>MERN Stack Developer</span>
          <p>I'm Skilled MERN stack developer with 2 years of experience, transforming ideas into reality with elegant code. Enthusiastic about learning and collaborating to create exceptional solutions and pushing boundaries togethe</p>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 d-flex align-items-stretch"   data-aos-delay="400">
      <div class="member">
        <div class="member-img">
          <img src={team4} class="img-fluid" alt="No_Image"/>
          <div class="social">
            <a href="https://www.facebook.com/home.php" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/techfusion752/" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href=""><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
        <div class="member-info">
          <h4>Amanda Jepson</h4>
          <span>Accountant</span>
          <p>Rerum voluptate non adipisci animi distinctio et deserunt amet voluptas. Quia aut aliquid doloremque ut possimus ipsum officia.</p>
        </div>
      </div>
    </div>

  </div>

</div>

</section>
    </>
  );
};
export default MyTeam;
