import React,{useState} from "react";
import "./StylingFiles/Style.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Contact = () => {
  const [data , setData] = useState({});

  const onChangeData=(e)=>{
    let obj = data;
    obj[e.target.name] = e.target.value;
    setData(obj);
  }
  const submit =()=>{
    console.log('data---',data);

    axios.post("http://localhost:5000/mail" , data)
    .then((response)=>{
      console.log('response---',response.data);
    })
    .catch((err)=>{
      console.log('err---',err);
    })
  }


  const PhoneOne = () => {
    window.location.href = `tel:${"+923232345673"}`;
  };
  const PhoneTwo = () => {
    window.location.href = `tel:${"+923447688477"}`;
  };

  return (
    <>
      <section id="contact" class="contact">
        <div class="container">
          <header class="section-header">
            {/* <!-- <h2>Contact</h2> --> */}
            <p>Contact Us</p>
          </header>

          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <Link to="https://www.google.com/maps/place/Sultan+Town,+Lahore,+Punjab,+Pakistan/@31.4601615,74.2362095,16z/data=!3m1!4b1!4m6!3m5!1s0x391901f552e07e25:0x5b08763078ca92ec!8m2!3d31.4605393!4d74.2421428!16s%2Fg%2F1hc55rw4q?entry=ttu" target="_blank">
                      Ali Town Lahore,
                      <br />
                      Punjab, Pakistan
                    </Link>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>
                      <Link to={`tel:${"+923232345673"}`} onClick={PhoneOne}>
                        +923 232345673
                      </Link>
                      <br />
                      <Link to={`tel:${"+923447688477"}`} onClick={PhoneTwo}>
                        +923 447688477
                      </Link>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      <Link to="mailto:syedmuzzamal752@gmail.com?subject=From%20Portfolio%20Here&body=Write Message Here!">
                        syedmuzzamal752@gmail.com{" "}
                      </Link>
                      <br />
                      <Link to="mailto:khmu752@gmail.com?subject=From%20Portfolio%20Here&body=Write Message Here!">
                        khmu752@gmail.com{" "}
                      </Link>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box">
                    <i class="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>
                      Monday - Friday
                      <br />
                      9:00AM - 05:00PM
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <span
                // action="forms/contact.php"
                // method="post"
                class="php-email-form"
              >
                <div class="row gy-4">
                  <div class="col-md-6">
                    <input
                      type="text"
                      name="name"
                      onChange={(e)=>onChangeData(e)}
                      class="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div class="col-md-6 ">
                    <input
                      type="email"
                      class="form-control"
                      onChange={(e)=>onChangeData(e)}
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      onChange={(e)=>onChangeData(e)}
                      name="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>

                  <div class="col-md-12">
                    <textarea
                      class="form-control"
                      name="message"
                      onChange={(e)=>onChangeData(e)}
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>

                    <button type="submit" onClick={submit}>Send Message</button>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
