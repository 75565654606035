import CountUp from "react-countup";
import "./StylingFiles/Style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
const CountProject = () => {
  return (
    <>
      <section id="counts" class="counts">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-emoji-smile"></i>
                <div>
                  <CountUp
                  start={0}
                  end={17}
                  duration={7}
                  className="purecounter"
                />
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i
                  class="bi bi-journal-richtext"
                  style={{ color: "#ee6c20" }}
                ></i>
                <div>
                    <CountUp
                  start={0}
                  end={17}
                  duration={7}
                  className="purecounter"
                />
                  <p>Projects</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-headset" style={{ color: "#15be56" }}></i>
                <div>
                        <CountUp
                  start={0}
                  end={900}
                  duration={7}
                  className="purecounter"
                />
                  <p>Hours Of Support</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="bi bi-people" style={{ color: "#bb0852" }}></i>
                <div>
                        <CountUp
                  start={0}
                  end={10}
                  duration={7}
                  className="purecounter"
                />
                  <p>Hard Workers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CountProject;
