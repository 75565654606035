import "./StylingFiles/Style.css";
import NavBar from "./NavBar";
import About from "./About";
import CountProject from "./CountProject";
import Portfolio from "./Portfolio";
import MyTeam from "./MyTeam";
import Contact from "./Contact";
import Footer from "./Footer";
import heroImg from "./img/hero-img.png";
import client1 from "./img/clients/client-1.png";
import client2 from "./img/clients/client-2.png";
import client3 from "./img/clients/client-3.png";
import client4 from "./img/clients/client-4.png";
import client5 from "./img/clients/client-5.png";
import client6 from "./img/clients/client-6.png";
import client7 from "./img/clients/client-7.png";
import client8 from "./img/clients/client-8.png";

const Home = () => {
  return (
    <>
      {/* ----------------------    NavBar    ---------------------- */}
      <NavBar />
      {/* ----------------------    END HOME    ---------------------- */}
      <section id="hero" class="hero d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <h1>We offer Web-Services to grow your businesses online</h1>
              <h2 data-aos-delay="400">
                Our team offers the best web development services along with
                digital marketing, graphics, and desktop app development.
              </h2>
              <div data-aos-delay="600">
                <div class="text-center text-lg-start">
                  <a
                    href="#about"
                    class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get Started</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src={heroImg} class="img-fluid" alt="Image_404" />
            </div>
          </div>
        </div>
      </section>
      {/* ----------------------    ABOUT SECTION    ---------------------- */}
      <main id="main">
        <About />
        {/* ----------------------    COUNT SECTION    ---------------------- */}
        <CountProject />
        {/* ----------------------    POTFOLIO SECTION    ---------------------- */}
        <Portfolio />
        {/* ----------------------    TESTIMONIAL SECTION    ---------------------- */}
        {/* <section id="testimonials" class="testimonials">

      <div class="container"  >

        <header class="section-header">
          <h2>Testimonials</h2>
          <p>What they are saying about us</p>
        </header>

        <div class="testimonials-slider swiper"   data-aos-delay="200">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/Rafakit.jpg" class="testimonial-img" alt="No_Image"/>
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                </div>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="No_Image"/>
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                </div>
              </div>
            </div>End testimonial item 


             <div class="swiper-slide">
              <div class="testimonial-item">
                <div class="stars">
                  <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                </div>
                <p>
                  Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                </p>
                <div class="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="No_Image"/>
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                </div>
              </div>
            </div>
            End testimonial item 

          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div> 

    </section> */}
        {/* ----------------------    TEAM SECTION    ---------------------- */}
        <MyTeam />
        {/* ----------------------    Client SECTION    ---------------------- */}
        <section id="clients" class="clients">
          <div class="container">
            <header class="section-header">
              {/* <!-- <h2>Our Clients</h2> --> */}
              <p>Our Clients</p>
            </header>

            <div class="clients-slider swiper">
              <div class="swiper-wrapper align-items-center">
                <div class="swiper-slide">
                  <img src={client1} class="img-fluid" alt="No_Image" />
                </div>
                <div class="swiper-slide">
                  <img src={client2} class="img-fluid" alt="No_Image" />
                </div>
                <div class="swiper-slide">
                  <img src={client3} class="img-fluid" alt="No_Image" />
                </div>
                <div class="swiper-slide">
                  <img src={client4} class="img-fluid" alt="No_Image" />
                </div>
                <div class="swiper-slide">
                  <img src={client5} class="img-fluid" alt="No_Image" />
                </div>
                <div class="swiper-slide">
                  <img src={client6} class="img-fluid" alt="No_Image" />
                </div>
                <div class="swiper-slide">
                  <img src={client7} class="img-fluid" alt="No_Image" />
                </div>
                <div class="swiper-slide">
                  <img src={client8} class="img-fluid" alt="No_Image" />
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </section>
        {/* ----------------------    Contact SECTION    ---------------------- */}
        <Contact />
      </main>
      {/* ----------------------    FOOTER SECTION    ---------------------- */}
      <Footer />
    </>
  );
};

export default Home;
