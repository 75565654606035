import "./StylingFiles/Style.css";
import NavBar from "./NavBar";
import aboutImg from "./img/about.jpg";


const About = () => {
  return (
    <>
      <section id="about" class="about">
        <div class="container">
          <div class="row gx-0">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center"
              data-aos-delay="200"
            >
              <div class="content">
                <h3>Who We Are</h3>
                <h2>Empowering Innovation with MERN Expertise</h2>
                <p>
                  At TechFusion, we are a group of highly skilled professionals
                  dedicated to revolutionizing web development through our
                  expertise in the MERN (MongoDB, Express.js, React.js, Node.js)
                  stack. With a remarkable track record of successfully
                  delivering 17 projects, we have honed our skills to
                  perfection, ensuring excellence in every endeavor.
                </p>
                <div class="text-center text-lg-start">
                  <a
                    href="#"
                    class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div
              class="col-lg-6 d-flex align-items-center"
              data-aos-delay="200"
            >
              <img src={aboutImg} class="img-fluid" alt="No_Image" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
