import "./StylingFiles/Style.css";
import logo from "./img/logo2-rmbg.png";
import { Link } from 'react-router-dom';

const Footer = () => {
    const handlePhoneClick = () => {
      window.location.href = `tel:${"+923232345673"}`;
    };
  return (
    <>
      <footer id="footer" class="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-5 col-md-12 footer-info">
                <a href="index.html" class="logo d-flex align-items-center">
                  <img src={logo} alt="No Image" />
                  <span>TechFusion</span>
                </a>

                <p>
                  
                Our skilled team provides top-notch services, dedicated to delivering optimal solutions to fulfill your needs – all with a budget-friendly approach. Experience the fusion of excellence, creativity, and affordability in one.
                </p>
                <div class="social-links mt-3">
                  <a
                    href="https://www.facebook.com/profile.php?id=100095171140297"
                    target="_blank"
                    class="facebook"
                  >
                    <i class="bi bi-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/techfusion752/  "
                    target="_blank"
                    class="instagram"
                  >
                    <i class="bi bi-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/syed-muzzamal-234a47235/"
                    target="_blank"
                    class="linkedin"
                  >
                    <i class="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i class="bi bi-chevron-right"></i> <a href="#">Home</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i> <a href="#">About us</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i> <a href="#">Services</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li style={{cursor:"pointer"}}>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>UI/UX Design</a>
                  </li>
                  <li style={{cursor:"pointer"}}>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Web Development</a>
                  </li>
                  <li style={{cursor:"pointer"}}>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Desktop App</a>
                  </li>
                  <li style={{cursor:"pointer"}}>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Digital Marketing</a>
                  </li>
                  <li style={{cursor:"pointer"}}>
                    <i class="bi bi-chevron-right"></i>{" "}
                    <a>Graphic Design</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                <Link to="https://www.google.com/maps/place/Sultan+Town,+Lahore,+Punjab,+Pakistan/@31.4601615,74.2362095,16z/data=!3m1!4b1!4m6!3m5!1s0x391901f552e07e25:0x5b08763078ca92ec!8m2!3d31.4605393!4d74.2421428!16s%2Fg%2F1hc55rw4q?entry=ttu" target="_blank">
                  Ali Town, Lahore <br/>
                  Punjab, Pakistan
                  </Link>
                  <br/>
                  {/* United States <br/> */}
                  <br/>
                  <strong>Phone:</strong> <Link to={`tel:${"+923232345673"}`} onClick={handlePhoneClick}>
                                            +923 232345673
                                          </Link>
                  <br/>
                  <strong>Email:</strong> <Link to="mailto:syedmuzzamal752@gmail.com?subject=From%20Portfolio%20Here&body=Write Message Here!" >syedmuzzamal752@gmail.com </Link>
                  {/* <Link to="mailto:khmu752@gmail.com?subject=From%20Portfolio%20Here&body=Write Message Here!" style={{color:"blue" , cursor:"pointer"}}>khmu752@gmail.com</Link> */}
                  <br/>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>TechFusion </span>
            </strong>
            . All Rights Reserved
          </div>
          <div class="credits">
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
