import "./StylingFiles/Style.css";
import portfolio1 from "./img/portfolio/portfolio-1.jpg";
import portfolio2 from "./img/portfolio/portfolio-2.jpg";
import portfolio3 from "./img/portfolio/portfolio-3.jpg";
import portfolio4 from "./img/portfolio/portfolio-4.jpg";
import portfolio5 from "./img/portfolio/portfolio-5.jpg";
import portfolio6 from "./img/portfolio/portfolio-6.jpg";
import portfolio7 from "./img/portfolio/portfolio-7.jpg";
import portfolio8 from "./img/portfolio/portfolio-8.jpg";
import portfolio9 from "./img/portfolio/portfolio-9.jpg";
import Project1 from "./img/Project 1.png";

const Portfolio = () => {
  return (
    <>
    <section id="portfolio" class="portfolio">

<div class="container"  >

  <header class="section-header">
    <h2>Portfolio</h2>
    <p>Check our latest work</p>
  </header>

  <div class="row"   data-aos-delay="100">
    <div class="col-lg-12 d-flex justify-content-center">
      <ul id="portfolio-flters">
        <li data-filter="*" class="filter-active">All</li>
        <li data-filter=".filter-app">App</li>
        <li data-filter=".filter-card">Card</li>
        <li data-filter=".filter-web">Web</li>
      </ul>
    </div>
  </div>

  <div class="row gy-4 portfolio-container"   data-aos-delay="200">

    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
      <div class="portfolio-wrap">
        <img src={Project1} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>App 1</h4>
          <p>App</p>
          <div class="portfolio-links">
            <a href={portfolio1} data-gallery="portfolioGallery" class="portfokio-lightbox" title="App 1"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
      <div class="portfolio-wrap">
        <img src={portfolio2} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>Web 3</h4>
          <p>Web</p>
          <div class="portfolio-links">
            <a href={portfolio2} data-gallery="portfolioGallery" class="portfokio-lightbox" title="Web 3"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
      <div class="portfolio-wrap">
        <img src={portfolio3} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>App 2</h4>
          <p>App</p>
          <div class="portfolio-links">
            <a href={portfolio3} data-gallery="portfolioGallery" class="portfokio-lightbox" title="App 2"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
      <div class="portfolio-wrap">
        <img src={portfolio4} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>Card 2</h4>
          <p>Card</p>
          <div class="portfolio-links">
            <a href={portfolio4} data-gallery="portfolioGallery" class="portfokio-lightbox" title="Card 2"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
      <div class="portfolio-wrap">
        <img src={portfolio5} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>Web 2</h4>
          <p>Web</p>
          <div class="portfolio-links">
            <a href={portfolio5} data-gallery="portfolioGallery" class="portfokio-lightbox" title="Web 2"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
      <div class="portfolio-wrap">
        <img src={portfolio6} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>App 3</h4>
          <p>App</p>
          <div class="portfolio-links">
            <a href={portfolio6} data-gallery="portfolioGallery" class="portfokio-lightbox" title="App 3"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
      <div class="portfolio-wrap">
        <img src={portfolio7} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>Card 1</h4>
          <p>Card</p>
          <div class="portfolio-links">
            <a href={portfolio7} data-gallery="portfolioGallery" class="portfokio-lightbox" title="Card 1"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
      <div class="portfolio-wrap">
        <img src={portfolio8} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>Card 3</h4>
          <p>Card</p>
          <div class="portfolio-links">
            <a href={portfolio8} data-gallery="portfolioGallery" class="portfokio-lightbox" title="Card 3"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
      <div class="portfolio-wrap">
        <img src={portfolio9} class="img-fluid" alt="No_Image"/>
        <div class="portfolio-info">
          <h4>Web 3</h4>
          <p>Web</p>
          <div class="portfolio-links">
            <a href={portfolio9} data-gallery="portfolioGallery" class="portfokio-lightbox" title="Web 3"><i class="bi bi-plus"></i></a>
            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

</section>
    </>
  );
};
export default Portfolio;